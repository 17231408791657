import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhoneAlt,
  faMapMarkerAlt,
  faQuoteRight,
} from '@fortawesome/free-solid-svg-icons'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { Link as GatsbyLink } from 'gatsby'

import {
  Blanket,
  BlanketBackground,
  BlanketTitle,
  BlanketContent,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { SEO } from '../../components/Seo'
import { Title } from '../../components/Title'

import * as classes from './contatti.module.scss'

interface LinkProps {
  link?: string
  [key: string]: any
}

const WrapLink: React.FC<LinkProps> = ({ children, link, ...props }) => {
  if (!link) {
    return <>{children}</>
  }

  return (
    <GatsbyLink to={link} {...props}>
      {children}
    </GatsbyLink>
  )
}

interface ContactsIcons {
  icon: any
  title: string
  subtitle?: string
  link?: string
}

interface ContactsSectionProps {
  contacts: ContactsIcons[]
  image: FluidObject
}

const ContactsSection: React.FC<ContactsSectionProps> = ({
  children,
  contacts,
  image,
}) => (
  <Section>
    <Container>
      <Row>
        {contacts.map((contact, index) => (
          <Col className={classes.column} key={index}>
            <WrapLink link={contact.link}>
              <FontAwesomeIcon
                className={classes.bigIcon}
                icon={contact.icon}
              />

              <Title as={'h6'}>{contact.title}</Title>

              {contact.subtitle && <p>{contact.subtitle}</p>}
            </WrapLink>
          </Col>
        ))}
      </Row>
    </Container>

    <GatsbyImage className={classes.gatsbyImage} fluid={image} />

    <Container size={'medium'}>{children}</Container>
  </Section>
)

const mainContacts = [
  {
    name: 'Ufficio stampa e comunicazione',
    email: 'media@pallacanestroreggiana.it',
  },
  {
    name: 'Segreteria e ticketing',
    email: 'segreteria@pallacanestroreggiana.it',
  },
  // { name: 'Giovanili', email: 'settoregiovanile@pallacanestroreggiana.it' },
  {
    name: 'Amministrazione',
    email: 'amministrazione@pallacanestroreggiana.it',
  },
]

const headOfficeContacts: ContactsIcons[] = [
  { icon: faPhoneAlt, title: 'Contatti', subtitle: '+39 0522 287950' },
  {
    icon: faMapMarkerAlt,
    title: 'Sede Legale',
    subtitle: 'Via Martiri della Bettola 47,  42123 Reggio Emilia',
  },
  { icon: faQuoteRight, title: 'Storia', link: '/società/la-storia' },
]

const arenaContacts: ContactsIcons[] = [
  { icon: faPhoneAlt, title: 'Contatti', subtitle: '+39 339 376 2122' },
  {
    icon: faMapMarkerAlt,
    title: 'Palabigi',
    subtitle: 'Via Guasco, 42121  Reggio Emilia, RE',
  },
  { icon: faQuoteRight, title: 'Storia Palabigi', link: '/palabigi' },
]

export default () => {
  const { headOffice, arena, entrance } = useStaticQuery(graphql`
    query Contatti {
      headOffice: file(name: { eq: "mappa-sede-legale" }) {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      arena: file(name: { eq: "mappa-sede-legale" }) {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      entrance: file(name: { eq: "contatti-area-ingressi" }) {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Blanket>
      <SEO title={'Contatti'} />

      <BlanketBackground>
        <Container>
          <BlanketTitle>Contatti</BlanketTitle>
        </Container>

        <BlanketContent className={classes.contactsMail}>
          <Section withContainer>
            <div className={classes.contact}>
              <div>
                <p className={classes.title}>Sponsor ed Eventi</p>
              </div>

              <div className={classes.mail}>
                <GatsbyLink to={'/diventa-sponsor'}>
                  vai alla sezione dedicata
                </GatsbyLink>
              </div>
            </div>

            {mainContacts.map((contact, index) => (
              <div className={classes.contact} key={index}>
                <div>
                  <p className={classes.title}>{contact.name}</p>
                </div>

                <div className={classes.mail}>
                  <FontAwesomeIcon className={classes.icon} icon={faEnvelope} />

                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </div>
              </div>
            ))}
          </Section>

          <ContactsSection
            contacts={headOfficeContacts}
            image={arena.childImageSharp?.fluid as FluidObject}
          />

          <div className={classes.map}>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2837.0603942082403!2d10.61389271585098!3d44.67754549360632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47801ceb6ef9f30d%3A0x2c2ea5c69d1d730f!2sVia%20Martiri%20della%20Bettola%2C%2047%2C%2042123%20Reggio%20Emilia%20RE!5e0!3m2!1sen!2sit!4v1579781155241!5m2!1sen!2sit'
              width='100%'
              height='450'
              frameBorder='0'
              allowFullScreen=''
            />
          </div>

          <ContactsSection
            contacts={arenaContacts}
            image={arena.childImageSharp?.fluid as FluidObject}
          />
          <div className={classes.map}>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2836.0045668893267!2d10.622673915851415!3d44.69910569218642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47801c5d1eb2f883%3A0xe3013b5aa65a6a8!2sPalasport%20Bigi!5e0!3m2!1sen!2sit!4v1579782524771!5m2!1sen!2sit'
              width='100%'
              height='450'
              frameBorder='0'
              allowFullScreen=''
            />
          </div>

          <Section>
            <Container size={'medium'}>
              <Title as={'h5'} className={classes.parkingTitle}>
                Dove Parcheggiare
              </Title>
              <ul>
                <li>Parcheggio Cecati - Via Cecati, 42123 Reggio Emilia RE</li>
                <li>
                  Parcheggio Caserma Zucchi - Piazzale Lancieri d'Aosta, 42121
                  Reggio Emilia RE{' '}
                </li>
                <li>Controviali - Viale Timavo, 42121 Reggio Emilia RE</li>
              </ul>
            </Container>
          </Section>

          {/*<Section>
            <GatsbyImage
              fluid={entrance.childImageSharp?.fluid as FluidObject}
            />
          </Section>*/}
        </BlanketContent>
      </BlanketBackground>
    </Blanket>
  )
}
