// extracted by mini-css-extract-plugin
export var bigIcon = "contatti-module--bigIcon--MfvFG";
export var column = "contatti-module--column--tooA+";
export var contact = "contatti-module--contact--nBJ00";
export var contactsMail = "contatti-module--contactsMail--+-mdM";
export var gatsbyImage = "contatti-module--gatsbyImage--2PNJO";
export var icon = "contatti-module--icon--sSmfA";
export var mail = "contatti-module--mail--Pt2Ns";
export var map = "contatti-module--map--7iTFr";
export var parkingTitle = "contatti-module--parkingTitle--p1u9O";
export var title = "contatti-module--title--cs2s6";